.form {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.button {
  height: 40px !important;
  padding: 0 1rem;
}