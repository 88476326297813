@use "@/styles/functions.scss" as utils;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.sheet {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: utils.rem(24);
  background: rgba(40, 40, 40);
  max-width: utils.rem(700);
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  overflow: hidden;
  overflow-y: auto;
}

.wrapper {
  margin-top: 1rem;
}