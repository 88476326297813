@use "../../../../styles/functions.scss" as utils;

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.dialog {
}


.back {
  text-decoration: none;
  align-self: center;
  color: white;
  font-size: utils.rem(13);
  font-weight: 500;
}