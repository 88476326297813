@use "@/styles/functions.scss" as utils;

.shakaPlayer {
  width: inherit;
  max-width: inherit;
  height: inherit;

  video {
    width: 100%;
    max-width: inherit;
    max-height: 50vh;
  }
}