@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.tabsContainer {
  display: flex;
  flex-direction: column;
  gap: utils.rem(36);
}

.tabContent {
  display: flex;
  justify-content: left;
  gap: utils.rem(80);
  padding: 0px utils.rem(24);
}

.contentBody {
  width: 100%;
  max-width: 605px;
}

.sectionTitle {
  width: 160px;

  & span {
    font-size: utils.rem(20);
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: utils.rem(16);
  margin-bottom: utils.rem(24);
}

.disableTitle {
  margin-bottom: utils.rem(12);
  font-size: utils.rem(20);
}

.disableSubtext {
  font-size: utils.rem(13);
  margin-bottom: utils.rem(12);
}

.button {
  width: 150px;
  height: 36px;
}

.disableAccountButton {
  background-color: var(--danger-100) !important;
  width: 150px;
  height: 36px;
}

.disableAccontWrapper {
  display: flex;
  flex-direction: column;
  gap: utils.rem(12);
}

.saveButton {
  width: utils.rem(100);
  height: utils.rem(39.5);
}

@media #{$mobileOnly} {
  .tabContent {
    flex-direction: column;
    gap: utils.rem(24);
    padding: 0;
  }

  .contentBody {
    max-width: 100%;
  }

  .sectionTitle {
    display: none;
  }

  .disableAccontWrapper {
    width: 100%;
  }

  .disableTitle,
  .disableSubtext {
    text-align: center;
  }

  .disableAccountButton,
  .saveButton {
    width: 100%;
  }
}
.account {
  width: 100%;
  height: 100%;
}

.pfpSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: utils.rem(16);

  .buttonContainer {
    display: flex;
    flex-direction: row;
    gap: utils.rem(16);
    margin: utils.rem(16) 0;

    @media #{$mobileOnly} {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  #updateAvatarButton {
    display: inline-block;
    min-width: utils.rem(175);
    padding: utils.rem(12);
    background: transparent;
    color: white;
    border-radius: utils.rem(40);
    border: 1px solid white;
    font-weight: 500;
    text-align: center;
    text-transform: none;
    &:hover {
      background: var(--brand-secondary);
      color: var(--dark-900);
      border: 1px solid var(--brand-secondary);
      transition: all 0.4s ease;
    }

    @media #{$mobileOnly} {
      font-size: utils.rem(14);
      padding-inline: utils.rem(10);
      min-width: utils.rem(155);
    }
  }

  .hiddenInput {
    display: none;
  }
}

.pfpWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userProfile {
  height: 100%;
  padding: 1rem 0;
}

.userProfile img {
  height: utils.rem(84);
  width: utils.rem(84);
  border-radius: 50%;
  object-fit: cover;
  border: utils.rem(2) solid var(--theme-color);
}

.tabs {
  display: flex;
  justify-content: center;
  & button {
    color: white;
  }
  margin: 1rem 0;
}

.backButton {
  padding: 0px utils.rem(24);
}

.pfpText {
  text-align: center;
}

.removeAvatarButton {
  color: var(--danger-200);
  cursor: pointer;
}

@media #{$mobileOnly} {
  .backButton {
    display: none;
  }
}
