@use "../../../../styles/functions.scss" as utils;

.iconButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: utils.rem(4) utils.rem(4) utils.rem(2);
  border-radius: utils.rem(8);
  transition: 0.3s ease all;

  &:hover {
    background: #282828;
  }
}

.notifMenuHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: utils.rem(64);

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: utils.rem(8);
    .headerText {
      color: white;
      font-weight: 600;
    }
  }

  .markAllButton {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: 400;
    &:disabled {
      color: #635e5e;
      cursor: not-allowed;
    }
  }
}

.notifItems {
  margin-top: utils.rem(16);
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: utils.rem(12);
  max-height: 450px;
  overflow-y: auto;
}

.notifItems::-webkit-scrollbar {
  background: transparent;
  width: utils.rem(8);
}
.notifItems::-webkit-scrollbar-thumb {
  background: #635e5e;
  border-radius: utils.rem(10);
}

.viewMoreButton {
  color: var(--brand-secondary);
  background: none;
  border: none;
  margin: utils.rem(5) auto 0;
  width: 100%;
  cursor: pointer;
  font-weight: 500;

  &:disabled {
    color: #635e5e;
    cursor: not-allowed;
  }
}

.emptyMessage {
  color: white;
  margin: utils.rem(32);
  text-align: center;
}
