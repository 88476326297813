@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.glassPane {
  box-shadow: 2px 4px 16px 0px #f8f8f80f inset;
  background: #ffffff14;
  border-radius: 1rem;
  margin: utils.rem(16) 0;
  padding: utils.rem(24);
  height: max-content;

  @media #{$mobileOnly} {
    padding: 0;
  }
}

.datatable {
  table {
    table-layout: fixed;
  }
}

// .dataTableContainer {
//   border-radius: utils.rem(8);
//   background-color: #72727221;
//   height: 85vh;
//   padding: 1rem;
// }

.tableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: utils.rem(16);

  .createFolderButton {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    cursor: pointer;
    justify-content: center;
    border: none;
    background: transparent;
    color: #7c899a;
    font-family: inherit;
    font-size: 13px;
    font-weight: 600;
    align-items: center;
    height: 40px;
    border-radius: 30px;
    padding: 8px 16px;
    transition:
      color 0.2s ease,
      background 0.2s ease;

    &:hover {
      color: #f8f8f8;
      background: #ffffff14;
    }

    @media #{$mobileOnly} {
      display: none;
    }
  }

  .mobileSortButton {
    display: flex;
    flex-direction: row;
    gap: 0.1rem;
    cursor: pointer;
    justify-content: center;
    border: none;
    background: transparent;
    color: var(--light-100);
    font-family: inherit;
    font-size: 13px;
    font-weight: 600;
    align-items: center;
    margin-left: utils.rem(5);

    @media #{$desktopOnly} {
      display: none;
    }
  }

  @media #{$mobileOnly} {
    flex-direction: column;
    gap: utils.rem(16);
  }

  @media #{$desktopOnly} {
    .searchBar {
      width: 40%;
      margin-left: auto;
      transition:
        width 0.3s ease-in-out,
        margin-left 0.3s ease-in-out;
    }
    @media (min-width: 1030px) {
      .searchBar:focus-within {
        width: min(100%, utils.rem(350));
      }
    }
  }
}

@media #{$mobileOnly} {
  .searchBar {
    width: 100%;
  }
}
.headerActions {
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 1rem;
}

.modalWidth {
  max-width: utils.rem(400) !important;
  width: 100% !important;
}

.emptyMessage {
  display: flex;
  flex-direction: column;
  gap: utils.rem(16);
  padding: utils.rem(16);
  text-align: center;
  font-size: 13px;

  svg {
    margin-left: utils.rem(8);
  }
}

// MOBILE
.glassPane {
  @media #{$mobileOnly} {
    background: none;
    box-shadow: none;

    .datatable {
      div[data-pc-section="header"] {
        background: none;
        padding: 0;
        margin-bottom: utils.rem(24);
      }

      table {
        tbody {
          display: flex;
          flex-direction: column;
          gap: 8px;

          tr {
            border: none;
            border-radius: 16px;
            background: #ffffff14;
            box-shadow: 2px 4px 16px 0px #f8f8f80f inset;
            display: grid;
            grid-template-columns: 2fr 1fr;
            padding: 16px;
            column-gap: 8px;
            row-gap: 16px;

            td {
              display: flex;
              min-width: 0;
              overflow: hidden;
              white-space: nowrap;
              padding: 0;
              border: none;
              align-items: center;
            }
          }

          tr:has(.emptyMessage) {
            display: flex;
            td {
              width: 100%;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.breadcrumbSpacer {
  transition: height 0.2s ease-in-out;
  overflow: hidden;
  height: utils.rem(0);

  &.grow {
    height: utils.rem(35);
  }
  &.shrink {
    height: utils.rem(0);
  }
}
