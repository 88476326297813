@use "./styles/functions.scss" as utils;

:root {
  --header-size: #{utils.rem(74)};

  --Surface-Background: #282828b2;
  --sds-size-stroke-border: 1px;
  --theme-color: #f9fafb;
  --border-radius: 40px;

  --brand-primary: #dffe0f;
  --brand-secondary: #e5fa0c;

  --brand-100: #defe05;
  --brand-200: #deff06;
  --brand-300: #DEFE05;

  --dark-100: #8b8b8b;
  --dark-200: #151615;
  --dark-300: #5e5c5c;
  --dark-400: #f8f8f8b2;
  --dark-500: #a2a2a255;
  --dark-600: #a2a2a2;
  --dark-700: #282828;
  --dark-800: #232424;
  --dark-900: #000000;
  --dark-1000: #232323;
  --dark-1100: #737373;
  --dark-1200: #6E6D6D;
  --dark-1300: #383838;
  --dark-1400: #565656;

  --light-shadow: rgba(21, 22, 21, 0.1);
  --active-color: #2e2e2e;

  --light-100: #ffffff;
  --light-200: #f8f8f8;
  --light-300: #e4e4e4;
  --light-400: #f3f3f3;
  --light-500: #868786;

  --danger-100: #ee434d;
  --danger-200: #ff3d3d;
  --danger-300: #EE434D;

  --green-100: #62dd68 !important;
  --green-200: #11FF00 !important;
}

// Responsive
$mobileOnly: "(max-width: 767px)";
$desktopOnly: "(min-width: 768px)";