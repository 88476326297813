@use "../../styles/functions.scss" as utils;

.mapContainer {
  display: flex;
  flex-direction: column;
  gap: utils.rem(16);

  .viewerInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: utils.rem(16);

    .sectionTitle {
      font-weight: 500;
      font-size: utils.rem(20);
    }
    .viewData {
      display: flex;
      flex-direction: row;
      gap: utils.rem(8);
      justify-content: center;
      align-items: center;
    }
    .recentViewers {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .activity {
      display: flex;
      flex-direction: column;
      gap: utils.rem(16);
      width: 100%;
      list-style: none;
      padding-left: 0;
      margin-top: 0;
      overflow-y: scroll;
      padding: 0;
    }
  }

  .switcherButtons {
    display: flex;
    flex-direction: row;
    gap: utils.rem(16);
    .switcherButton {
      text-align: center;
      border-radius: utils.rem(8);
      padding: utils.rem(8) utils.rem(16);
      border: none;
      color: #ffffff80;
      background-color: #ffffff08;
      line-height: 1.5;
      font-weight: 500;
      cursor: pointer;
      &.active {
        background-color: #ffffff1a;
        color: white;
      }
    }
  }
}
