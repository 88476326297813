.galleryContainer {
  width: 100%;
  height: 100%;
  // background: radial-gradient(circle at 50% 300px, #d65329 0%, rgb(225 156 156) 40%, #ffffff 100%);
  background: radial-gradient(circle at 50% 300px, #ffebcd 0%, #e6c7a6 40%, #9c7e69 80%, #3b2e2a 100%);
}

.backgroundVideoWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;

  video {
    filter: blur(50px);
  }

  img {
    filter: blur(50px);
  }
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(to top right, #5F5F5F 0%, #6E6D6D 100%);
  opacity: 0.75;
}

.backgroundVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /* Ensures the video is below the overlay */
}

.testingPanel {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.blackText {
  color: black;
}

.whiteText {
  color: white;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 2rem;
}