@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.informationTabWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.metadataList {
  list-style-type: none;
  margin: 0;
  padding: 16px;
  background: var(--dark-200);
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: utils.rem(16);

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: utils.rem(16);

    span:first-of-type {
      width: fit-content;
      color: var(--brand-100);
    }

    span:nth-of-type(2) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: right;
    }
  }
}

.unsupportedBrowserText {
  text-align: center;
  padding: utils.rem(30);
}

.metadataOwner {
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    width: 24px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 2px solid white;
  }
}

.metadataHash {
  span:nth-of-type(2) {
    width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--dark-400);
  }
}

.sharedUsersGrid {
  display: grid;
  grid-template-columns: 1fr 0.3fr utils.rem(40);
  column-gap: 8px;
  font-size: 13px;
  margin-top: 32px;
  align-items: center;
  row-gap: 16px;

  @media #{$mobileOnly} {
    grid-template-columns: 1fr 0.3fr utils.rem(32);
  }
}

.sharedWithListWrapper {
  padding-bottom: utils.rem(64);
}

.sharedUsersList {
  display: contents;
  grid-column: 1 / span 3;

  li {
    display: contents;

    div:first-of-type {
      grid-column: 1 / span 1;
      gap: 8px;
      word-break: break-all;
    }

    div:nth-of-type(2) {
      display: contents;

      select {
        grid-column: 2 / span 1;
      }

      button {
        grid-column: 3 / span 1;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
