@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.fileListFilter {
  .quickActions {
    border: 1px solid var(--dark-300);
    border-radius: utils.rem(8);
    box-shadow: 0 utils.rem(4) utils.rem(4) 0 var(--dark-900) inset;
    padding: utils.rem(8) utils.rem(16);
    height: max-content;
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: utils.rem(360);
    background: var(--light-shadow);

    @media #{$mobileOnly} {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      border: none;
      background: transparent;
      box-shadow: none;
      font-size: 13px;
      padding: 8px 0;
    }

    .fileAction {
      background-color: transparent;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      border-width: 0;
      text-align: center;
      color: #f8f8f899;
      cursor: pointer;
      width: utils.rem(120);
      font-size: utils.rem(16);
      padding: utils.rem(10) utils.rem(24);

      @media #{$mobileOnly} {
        width: 100%;
        padding-inline: 0;
        font-size: utils.rem(13);
      }

      &.active {
        color: var(--light-100);
        background: var(--active-color);
        border-radius: utils.rem(8);

        @media #{$mobileOnly} {
          border-radius: 50px;
        }
      }
    }
  }
}
