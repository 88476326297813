@use "../../../../../styles/functions.scss" as utils;

.deleteConfirmation {
  display: flex;
  flex-direction: column;
  gap: utils.rem(16);

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: utils.rem(16);

    
  }
}
