@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.container {
  display: flex;
  position: relative;
}

.crumbsContainer {
  display: flex;
  position: absolute;
  white-space: nowrap;
  gap: 1rem;
  top: utils.rem(13);
}

.breadcrumb {
  font-weight: bold;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;

  // button reset
  border: none;
  background: none;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  &::after {
    content: "|";
    margin-left: 1rem;
    font-weight: normal;
  }
}

.currentFolder {
  opacity: 0.5;
}

.breadcrumbSpacer {
  transition: height 0.2s ease-in-out;
  overflow: hidden;
  height: utils.rem(0);

  &.grow {
    height: utils.rem(30);
  }
  &.shrink {
    height: utils.rem(0);
  }
}
