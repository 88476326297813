@use "../../../../styles/functions.scss" as utils;

.notificationItem {
  display: flex;
  flex-direction: column;
  color: white;
  gap: utils.rem(8);
  padding: utils.rem(16);
  justify-content: space-between;
  font-family: inherit;
  font-size: utils.rem(14);
  border-radius: utils.rem(10);

  .profileAvatar {
    border-radius: 50%;
    width: utils.rem(30);
    height: utils.rem(30);
    aspect-ratio: 1/1;
    border: 2px solid white;
  }

  .notificationDate {
    color: #dedede80;
  }

  .notificationInfoContainer {
    display: flex;
    flex-direction: col;
    gap: utils.rem(15);

    .notificationText {
      display: flex;
      flex-direction: column;
      gap: utils.rem(8);

      .boldNotificationText {
        font-weight: 600;
      }
    }
  }

  &.unread {
    background-color: #363535;
  }

  &:hover {
    background: #363535;
    transition: all 0.4s ease;
  }

  .notifMarker {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    flex-shrink: 0;

    &.unreadMarker {
      background-color: var(--danger-200);
    }

    &.readMarker {
      background-color: #635e5e;
    }
  }

  .actionButtonContainer {
    margin-left: utils.rem(45);
    margin-top: utils.rem(10);
    display: flex;
    flex-direction: row;
    gap: utils.rem(12);

    .actionButton {
      background-color: #403f3f;
      border: none;
      color: white;
      padding: utils.rem(11);
      border-radius: utils.rem(5);
      cursor: pointer;

      &.denyAction {
        color: var(--danger-200);
        background-color: #333;
      }
      &:hover {
        background-color: #2f2e2e;
        transition: all 0.2s ease;
      }
    }
    .accessResponse {
      font-weight: 500;
    }
  }

  .deleteButton {
    align-self: flex-end;
    background: transparent;
    color: var(--light-200);
    width: utils.rem(20);
    height: utils.rem(20);
    border: none;
    border-radius: utils.rem(10);
    cursor: pointer;

    &:hover {
      background-color: #2f2e2e;
      transition: all 0.2s ease;
    }
  }
}
