@use "./functions.scss" as utils;
@use "./variables.scss" as *;
@import url("./components/index.scss");
@import url("./animations.scss");
@import url("./functions.scss");
@import url("./reset.scss");
@import url("./typography.scss");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family:
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.content {
  position: relative;
  z-index: 1;
  color: var(--light-100);
}

.page-layout {
  padding: 2rem;
}

.text-bold {
  font-weight: 500;
}

.text-dimmed {
  color: var(--dark-100);
}

.h-70 {
  height: 70vh;
}

.brand-secondary-bg {
  background-color: var(--brand-secondary) !important;
}

.text-white {
  color: var(--light-100) !important;
}

.text-dark {
  color: var(--dark-700) !important;
}

.super-button:hover {
  filter: none !important;
}

.w-100 {
  width: 100%;
}

.page-content {
  width: 100%;
  height: unset;
  padding: utils.rem(20);
  border-radius: 1rem;
  background: #ffffff14;
  box-shadow: 2px 4px 16px 0px #f8f8f80f inset;
  backdrop-filter: blur(utils.rem(20));
}

.page-content-wrapper {
  background-color: #72727221;
  padding: 24px 16px 24px 16px;
  height: 75vh;
  overflow-y: scroll;
  border-radius: 8px;
}

.round-image-with-border {
  border-radius: 50%;
  object-fit: cover;
  border: 0.125rem solid var(--theme-color);
  aspect-ratio: 1/1;
}

// .content-wrapper {
//   padding: 16px;
//   background-color: #232424;
//   border-radius: 8px;
//   box-shadow: 2px 4px 16px 0px #F8F8F80F inset, 0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .6);
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
// }

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media #{$mobileOnly} {
  .page-layout {
    padding: 1rem;
  }

  .page-content {
    background: transparent;
    box-shadow: none;
    backdrop-filter: none;
    padding: 0px;
    margin: 8px 0px 16px;
  }

  .page-content-wrapper {
    background-color: transparent;
    padding: utils.rem(8);
    height: fit-content;
  }

  .mobile-only-bg-blur {
    backdrop-filter: blur(20px);
  }
}

.shaka-video-container {
  .shaka-controls-container {
    .shaka-controls-button-panel {
      overflow: inherit;

      .shaka-overflow-button-label {
        top: -48px;
      }
    }
  }

  &.pdfControls {
    .shaka-mute-button,
    .shaka-volume-bar-container,
    .shaka-small-play-button,
    .shaka-current-time,
    #shaka-player-ui-time-container,
    .shaka-playbackrate-button {
      display: none;
    }

    .shaka-seek-bar-container {
      transform: rotate(90deg);
      position: absolute;
      top: 50%;
      right: -13vh;
      left: auto;
      width: 30vh;
    }

    .shaka-scrim-container {
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.5) 0,
        rgba(0, 0, 0, 0) 10%
      );
    }

    .shaka-controls-button-panel {
      flex-direction: column;
      align-items: end;
    }
  }
}
