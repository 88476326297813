@use "@/styles/functions.scss" as utils;

.dropdownWrapper {
  position: fixed;
  max-width: utils.rem(300);
  max-height: 200px;
  z-index: 999;
  background: #333;
  padding: 8px;
  margin-block: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.dropdownList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  background: rgb(40, 40, 40);
  display: grid;
}

.dropdownItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  font-size: 14px;
  color: var(--light-200);
  transition: none;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
}

.dropdownItem:hover,
.dropdownItem:focus {
  color: #000;
  background: var(--light-400);
}

.dropdownItemAvatar {
  width: 32px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 100%;
  border: 2px solid #fff;
}
