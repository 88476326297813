@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.ownedByCell {
  color: rgb(248, 248, 248);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  max-width: 100%;

  // button reset
  border: none;
  background: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;

  &:hover {
    color: var(--brand-secondary);
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid white;
    flex-shrink: 0;
  }

  .ownerName {
    padding: utils.rem(6) 0;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.fileTypeColumn {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgb(248, 248, 248);
}

.dateCell {
  color: rgb(248, 248, 248);

  @media #{$mobileOnly} {
    margin-left: auto;
  }
}

.rowActionCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: utils.rem(8);

  .iconContainer {
    border-radius: 50%;
    width: 48px;
    aspect-ratio: 1/1;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: #00000066;

      &:disabled {
        background-color: transparent;
      }
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  button {
    border: none;
    background: none;
    color: var(--light-200);
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
  }

  .requestAccessButton {
    color: var(--brand-primary);

    &:disabled {
      cursor: not-allowed;
      color: var(--light-500);
    }
  }

  @media #{$mobileOnly} {
    margin-left: auto;
    flex-wrap: wrap;

    .iconContainer {
      width: 36px;
    }
  }
}

.bulkActions,
.bulkActionButton {
  display: flex;
  flex-direction: row;
  gap: utils.rem(8);
}

.bulkActions {
  justify-content: flex-end;
  width: 100%;

  .deleteAllButton {
    color: var(--danger-200);
    border-radius: utils.rem(50);
    transition: all 0.3s ease;

    &:hover:not(:disabled) {
      background-color: var(--danger-200);
      color: var(--light-200);
    }

    &:disabled {
      cursor: not-allowed;
      color: grey;
    }
  }

  .moveToFolderButton,
  .deleteAllButton {
    padding-inline: utils.rem(4);
  }
}

.bulkActionButton {
  // button reset
  border: none;
  background: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    color: grey;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.menuItem {
  background-color: transparent !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
}

.colorPrimary {
  background-color: white !important;
  border-radius: 8px !important;
}

.barColorPrimary {
  color: var(--brand-primary) !important;
  background-color: var(--brand-primary) !important;
}

.modalWidth {
  max-width: utils.rem(400) !important;
  width: 100% !important;
}

@media #{$mobileOnly} {
  .infoIconMobile {
    height: 70%;
  }

  .rowActionCell {
    .mobileFolderOpen {
      background-color: #ffffff10;
      padding: utils.rem(7);
      border-radius: utils.rem(8);
    }
  }
}

// Column widths
@media #{$desktopOnly} {
  .selectionWrapper {
    width: utils.rem(48);
  }

  .nameTemplateWrapper {
    width: 40%;
  }

  .fileTypeWrapper {
    width: 15%;
  }

  .dateWrapper {
    width: 20%;
  }

  .ownerWrapper {
    width: 25%;
  }

  .actionRowWrapper {
    width: utils.rem(240);
  }
}