@use "../../../styles/functions.scss" as utils;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  padding: utils.rem(24) utils.rem(40);
  flex-direction: column;
  align-items: flex-end;
  gap: utils.rem(16);
  border-radius: utils.rem(32);
  overflow-y: auto;
  overflow-x: hidden;
  border: utils.rem(1.5) solid rgba(255, 255, 255, 0.1);
  background: rgba(40, 40, 40);
  width: 100%;
  max-width: utils.rem(720);
  box-shadow: utils.rem(2) utils.rem(4) utils.rem(16) 0px
    rgba(248, 248, 248, 0.06) inset;
  backdrop-filter: blur(utils.rem(50));
}

.modalActions {
  margin-top: utils.rem(24);
}
