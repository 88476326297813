@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.header {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(
    102.06deg,
    rgba(255, 255, 255, 0.4) 1.12%,
    rgba(255, 255, 255, 0.04) 99.58%
  );
  border-radius: utils.rem(40);
  margin-bottom: 1rem;
  z-index: 1;
}

.headerLogo {
  height: utils.rem(48);
}

.headerLogo:hover {
  cursor: pointer;
}

.userProfile {
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: utils.rem(16);
}

.userProfile button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.userProfile button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.userProfile img {
  height: utils.rem(48);
}

.mobileMenuContainer {
  text-align: center;

  img {
    height: utils.rem(48);
    margin-top: 1rem;
  }

  .menuFooter {
    margin: 1rem 0;
  }
}

.menuContainer {
  position: absolute;
  top: 110%;
  right: 0;
  background: var(--dark-800);
  padding: 1rem;
  border-radius: 8px;
  z-index: 2;
  width: 340px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow:
    2px 4px 16px 0px #f8f8f80f inset,
    0 0 0 rgba(0, 0, 0, 0.6);
}

.menuContainer:hover {
  cursor: default;
}

.signOutLink {
  border: none;
  background: none;
  font-family: inherit;
  font-size: inherit;
  color: var(--brand-100);
  text-align: center;
  width: fit-content;
  margin-inline: auto;
  cursor: pointer;
}

.manageAccountButton {
  padding: 10px !important;
  height: auto;
}

.menuHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.menuFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.username {
  font-size: 1.5rem;
  text-align: center;
}

@media #{$mobileOnly} {
  .header {
    background: transparent;
    padding: 0;
  }
}

.button {
  height: 36px;
}

.loginButton {
  backdrop-filter: none;
}
