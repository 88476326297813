.bounce {
  animation-name: sfBounce;
  transform-origin: center bottom;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes sfBounce {
  0%,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -7.5px, 0) scaleY(1.1);
    transform: translate3d(0, -7.5px, 0) scaleY(1.1);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.05);
    transform: translate3d(0, -4px, 0) scaleY(1.05);
  }
  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0) scaleY(0.95);
    transform: translateZ(0) scaleY(0.95);
  }
  90% {
    -webkit-transform: translate3d(0, -1px, 0) scaleY(1.02);
    transform: translate3d(0, -1px, 0) scaleY(1.02);
  }
}
