@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;

  > div {
    display: flex;
    flex-direction: column;

    h2 {
      font-weight: 500;
      margin-bottom: utils.rem(8);
    }
  }

  .mobileCancelButton {
    color: var(--light-100);
    width: utils.rem(32);
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--dark-500);
    cursor: pointer;
    padding: 0;
    border: none;
    background: none;
    font-size: inherit;

    @media #{$desktopOnly} {
      display: none;
    }
  }
}

.subtextContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filesList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px 0;
  margin: 24px 0;
}

.fileItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  border-radius: 32px;
  border: 1.5px solid var(--dark-500);
  background: var(--Surface-Background, rgba(40, 40, 40, 0.7));
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  backdrop-filter: blur(50px);
}

.fileContent {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.fileIcon {
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileIcon img {
  width: 100%;
}

.fileMeta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
}

.fileName {
  font-size: 13px;
}

.fileSize {
  font-size: 10px;
  line-height: 1.1;
  color: var(--dark-600);
}

.publicToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.toggleText {
  color: var(--dark-600);
  font-size: 12px;
}

.shareWithText {
  font-size: 14px;
  margin-bottom: 24px;
}

.sharedUsersWrapper {
  margin-block: 24px;
  max-height: 200px;
  overflow-y: auto;
}

.sharedUsersList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 0;
  padding-left: 0;
}

.footerActions {
  display: flex;
  justify-content: space-between;
}

.subtext {
  font-size: 13px;
  color: var(--dark-400);
  font-weight: 700;
}

.fileActions {
  display: flex;
  gap: 4px;
  align-items: center;
}

.fileActionWrapperEdit,
.fileActionWrapperDelete {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light-100);

  // button reset
  border: none;
  background: none;
}

.fileActionWrapperDelete {
  border-radius: 50% !important;
}

.cancelEditButton {
  width: 100px;
  height: 39px;
}

.cancelButtonSpacing {
  margin-left: 1rem;
}

// Responsive
.sharedWrapper,
.publicToggle {
  @media #{$mobileOnly} {
    display: none;
  }
}

.desktopCancelButton {
  @media #{$mobileOnly} {
    display: none !important;
  }
}

.superButtonWrapper {
  @media #{$mobileOnly} {
    width: 100%;

    button {
      width: 100%;
    }
  }
}
