@use "@/styles/variables.scss" as *;
@use "@/styles/functions.scss" as utils;

.container {
  width: 100%;
  height: 100vh;
  overflow: auto;

  .header {
    background-color: var(--dark-900);
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftControls {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      gap: 1rem;

      .headerPfp {
        object-fit: cover;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }

      .assetName {
        color: var(--brand-300);
        cursor: pointer;
        pointer-events: all;
      }

      .public {
        color: var(--green-200);
      }

      .restricted {
        color: var(--danger-300);
      }
    }

    .rightControls {
      display: flex;
      align-items: center;
      gap: 1rem;

      .headerLogo {
        width: 65px;
        height: 65px;
        background-color: var(--brand-secondary);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 30px;
          height: 30px;
        }
      }

      span {
        pointer-events: all;
        cursor: pointer;
      }
    }
  }

  .playerWrapper {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: 75%;
    perspective: 1000px;
    outline-offset: 48px;
    transition:
      width 1s ease-in-out,
      height 1s ease-in-out;
    pointer-events: all;
    margin-top: 32.5px;
  }

  .playerWrapper.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .playerWrapper.pdf {
    position: relative;

    @media #{$mobileOnly} {
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll;
    }
  }

  .playerContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    opacity: 1;
    // transition: opacity 0.5s ease-out;
    box-shadow: 0px 34px 38px 0px rgba(0, 0, 0, 0.6);
  }

  .playerContainer.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .playerContainer video {
    width: 100%;
    height: 100%;
    max-height: unset !important;
    object-fit: fill;
  }

  .overlayBg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .overlayBg.white {
    background-color: white;
  }

  .overlayContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .overlayVideo {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .overlayImage {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    max-width: 200px;
    max-height: 200px;
  }

  .unsupportedBrowserText {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
}

.headerLogoButton,
.headerAvatarButton {
  cursor: pointer;
  pointer-events: all;
  background: transparent;
  border: none;
}

.headerLogoButton {
  width: 100%;
  height: 100%;
}

@media #{$mobileOnly} {
  .playerWrapper {
    margin-top: 0 !important;
  }
}
