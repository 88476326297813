.profileAction {
  display: flex;
  align-items: center;
  border-radius: 70px;
  background: rgba(114, 114, 114, 0.05);
  gap: 9px;
  padding: 8px 24px;
  height: 42px;

  &:hover {
    cursor: pointer;
  }
}

.disabled {
  pointer-events: none !important;
  opacity: 0.5 !important;

  &:hover {
    cursor: default;
  }
}

.red {
  color: var(--danger-200);
}