@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.log {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: utils.rem(14);
  gap: utils.rem(16);

  @media #{$mobileOnly} {
    gap: utils.rem(10);
  }

  .logInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: utils.rem(16);
    min-width: 0;

    @media #{$mobileOnly} {
      gap: utils.rem(10);
    }

    .userProfilePic {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid white;
      flex-shrink: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .activityIcon {
      flex-shrink: 0;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .logText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;

    & > * {
      display: inline;
    }

    a, span {
      color: white;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: var(--brand-secondary);
      }
    }
  }

  .date {
    flex-shrink: 0;
  }
}
