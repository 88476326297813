@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.desktopProfileSection {
  width: 250px;
  position: relative;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--dark-1000, rgba(255, 255, 255, 0.08));
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  height: 85vh;
}

.mobileProfileSection {
  display: none;
}

.sidepanelWrapper {
  display: flex;
  height: 100%;
  padding: 13px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(114, 114, 114, 0.13);
}

.top,
.bottom {
  width: 100%;
}

.topActions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.pfp {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1rem 0;
  gap: 1rem;

  img {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 767px) {
  .mobileProfileSection {
    display: flex;
    gap: 1rem;

    .mobileProfileLeft {
      img {
        width: 50px;
        height: 50px;
      }
    }

    .mobileProfileRight {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .profileActions {
        display: flex;
        justify-content: space-between;
        gap: 1rem;

        .profileActionButton {
          border-radius: 8px;
          color: white;
          background: #333333;
          text-transform: capitalize;
        }

        .disabled {
          cursor: not-allowed;
          pointer-events: none;
          opacity: 0.5;
        }
      }

      .link {
        display: flex;
        gap: 1rem;
        align-items: center;

        a {
          color: var(--brand-primary);
          text-decoration: none;
        }
      }
    }
  }

  .desktopProfileSection {
    display: none;
  }
}

.user {
  width: 100%;
  height: 100%;
}

.profileWrapper {
  display: flex;
  gap: 1rem;
  height: 100%;
  max-height: 85vh;
}

.mobileProfileSection {
  display: none;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 902px;

  @media #{$desktopOnly} {
    div:nth-child(2) {
      margin: 0;
      margin-top: utils.rem(4);
    }
    div:nth-child(1) {
      margin: 0;
    }
  }
}

.page {
  height: 100%;

  & div {
    height: 100%;
  }
}

.sidepanelWrapper {
  display: flex;
  height: 100%;
  padding: 13px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(114, 114, 114, 0.13);
}

.backButton {
  margin-bottom: 1rem;
  display: inline-block;
}

.pfp {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1rem 0;
  gap: 1rem;

  img {
    width: 35px;
    height: 35px;
  }
}

.top,
.bottom {
  width: 100%;
}

.topActions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fileStatsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .fileStats {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    gap: 8px;
  }
}

.statsTitle {
  font-size: 10px;
}

.statsValue {
  font-size: 13px;
}

.tableHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: utils.rem(16);

  .mobileSortButton {
    display: flex;
    flex-direction: row;
    gap: 0.1rem;
    cursor: pointer;
    justify-content: center;
    border: none;
    background: transparent;
    color: var(--light-100);
    font-family: inherit;
    font-size: 13px;
    font-weight: 600;
    align-items: center;
    margin-left: utils.rem(5);

    @media #{$desktopOnly} {
      display: none;
    }
  }

  @media #{$mobileOnly} {
    .headerActions {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: utils.rem(20);
    }
  }

  @media #{$desktopOnly} {
    .headerActions {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 40%;
    }
  }
}

@media #{$mobileOnly} {
  .profileWrapper {
    flex-direction: column;
  }

  .desktopProfileSection {
    display: none;
  }

  .mobileProfileSection {
    display: flex;
    gap: 1rem;

    .mobileProfileLeft {
      img {
        width: 50px;
        height: 50px;
      }
    }

    .mobileProfileRight {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .profileActions {
        display: flex;
        justify-content: space-between;
        gap: 1rem;

        .profileActionButton {
          border-radius: 8px;
          color: white;
          background: #333333;
          text-transform: capitalize;
        }

        .disabled {
          cursor: not-allowed;
          pointer-events: none;
          opacity: 0.5;
        }
      }

      .link {
        display: flex;
        gap: 1rem;
        align-items: center;

        a {
          color: var(--brand-primary);
          text-decoration: none;
        }
      }
    }
  }
}
