.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.countColor {
  color: #ABABAB;
}

.view {
  color: #ABABAB;
  width: 15px;
  height: 15px;
}