.promptSignInContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goToLoginButton {
  height: 40px !important;
  margin-top: 0.8rem !important;
}
