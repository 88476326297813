@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.sideSheetTabs {
  width: 100%;
  margin-top: utils.rem(16);

  .tabs {
    position: relative;
    display: flex;
    border: 1px solid var(--dark-300);
    border-radius: utils.rem(8);
    background: var(--dark-200);
    padding: 0.75rem 1rem;
    box-shadow: 0 0.25rem 0.25rem 0 var(--dark-900) inset;

    @media #{$mobileOnly} {
      padding-inline: 0.5rem;
    }

    .activeBackground {
      position: absolute;
      top: 0.5rem;
      bottom: 0.5rem;
      background: var(--active-color);
      border-radius: utils.rem(8);
      z-index: 1;
      pointer-events: none;
    }

    .infoTab {
      position: relative;
      flex: 1;
      background-color: transparent;
      border: none;
      color: #f8f8f899;
      padding: utils.rem(10) utils.rem(24);
      font-size: utils.rem(16);
      cursor: pointer;
      z-index: 2;

      &.active {
        color: var(--light-100);
      }

      @media #{$mobileOnly} {
        font-size: utils.rem(14);
        padding-inline: utils.rem(8);
      }
    }
  }
}
