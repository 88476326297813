.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.button {
  height: 36px;
}