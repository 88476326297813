@use "../../../styles/functions.scss" as utils;

.loginScreen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  max-width: utils.rem(450);
  width: 100%;
  text-align: center;
}

.imageWrapper {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;

  // button reset
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.forgotPassword {
  text-decoration: none;
  align-self: center;
  color: white;
  font-size: utils.rem(13);
  font-weight: 500;
}
