@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.fileNameCell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: utils.rem(8);
  align-items: center;
  cursor: pointer;
  max-width: 100%;
  // button reset
  border: none;
  background: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: rgb(248, 248, 248);

  &:hover span:not(.mobileAssetMetadata span) {
    color: var(--brand-secondary);
  }

  span {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    transition: color 0.2s;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .fileCreatedDate {
    color: var(--dark-600);
  }

  .privateVisibility {
    color: var(--danger-100);
  }
  .publicVisibility {
    color: var(--green-100);
  }

  .fileTypeIcon,
  .folderIconWrapper {
    height: 30px;
    width: 30px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media #{$mobileOnly} {
  .mobileAssetContainer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
    max-width: 100%;
    padding: utils.rem(5);
  }

  .fileName {
    display: inline-block;
    text-align: left;
    width: 100%;
  }
  .mobileAssetMetadata {
    display: flex;
    padding-top: utils.rem(1);
  }
}
