.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dialog {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form button {
  margin-top: 24px;
}

.inputs {
  display: flex;
  gap: 16px;
}

.confirmationCodeInput {
  width: 57px !important;
  height: 77px;
  text-align: center;
  font-size: 24px;
  border-radius: 8px;
  border: 1px solid #5e5c5c !important;
  background: #151615;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.45) inset;
  color: white;
}

.description {
  color: rgba(255, 255, 255, 0.9) !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  text-decoration: underline;
  text-decoration-color: white !important;
  cursor: pointer;

  // button reset
  border: none;
  background: none;
  padding: 0;
}
