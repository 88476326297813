.actionText {
  display: flex;
  align-items: center;
  cursor: pointer;

  // button reset
  border: none;
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: 16px;
  padding-left: 0;
}

.text {
  font-size: 13px;
}