@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.userCell {
  color: rgb(248, 248, 248);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  max-width: 100%;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid white;
  }

  .userName {
    font-size: utils.rem(13);
    font-family: inherit;
    color: var(--light-100);
    padding-block: utils.rem(6);
    cursor: pointer;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    .userName {
      color: var(--brand-secondary);
    }
  }
}

.shareCount {
  color: rgb(248, 248, 248);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media #{$mobileOnly} {
    margin-left: auto;
  }
}

.actionsRow {
  @media #{$mobileOnly} {
    grid-column: 1 / span 2;
  }
}

.actionsCell {
  display: flex;
  gap: utils.rem(8);
  align-items: center;
  justify-content: flex-end;

  .viewProfileButton {
    cursor: pointer;
    color: var(--light-500);
  }

  a,
  button {
    all: initial;
    font-size: utils.rem(13);
    font-family: inherit;
    cursor: pointer;
    height: utils.rem(48);
    padding-inline: utils.rem(8);
  }

  a {
    color: var(--light-500);
    display: flex;
    align-items: center;
  }

  button {
    color: var(--danger-100);
  }

  @media #{$mobileOnly} {
    width: 100%;
    justify-content: space-between;
  }
}

.bulkActions {
  display: flex;
  flex-direction: row;
  gap: utils.rem(8);
  justify-content: flex-end;
  width: 100%;

  .unshareAllButton {
    all: initial;
    font-size: utils.rem(13);
    font-family: inherit;
    color: var(--danger-200);
    display: flex;
    flex-direction: row;
    gap: utils.rem(8);
    border-radius: utils.rem(50);
    transition: all 0.3s ease;
    padding-inline: utils.rem(4);
    cursor: pointer;

    &:hover {
      background-color: var(--danger-200);
      color: var(--light-200);
    }
  }
}

// Column widths
@media #{$desktopOnly} {
  .selectionWrapper {
    width: utils.rem(48);
  }

  .userNameWrapper {
    width: 67%;
  }

  .numOfAssetsWrapper {
    width: 33%;
  }

  .actionsWrapper {
    width: utils.rem(240);
  }
}
