.container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.fileStats {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 8px;
}

.statsTitle {
  font-size: 10px;
}

.statsValue {
  font-size: 13px;
}