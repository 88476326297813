@use "../../../../styles/functions.scss" as utils;

.logContainer {
  overflow-y: scroll;
  .logs {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: utils.rem(16);
    width: 100%;
    padding-left: 0;
  }
}
