@use "@/styles/functions.scss" as utils;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.modal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  pointer-events: none;
}

.modalActions {
  margin-top: utils.rem(24);
}
