@use "../../styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

#updateBackgroundButton {
  display: inline-block;
  min-width: utils.rem(175);
  padding: utils.rem(12);
  background: transparent;
  color: white;
  border-radius: utils.rem(40);
  border: 1px solid white;
  font-weight: 500;
  text-align: center;
  text-transform: none;
  &:hover,
  &:active {
    background: var(--brand-secondary);
    color: var(--dark-900);
    border: 1px solid var(--brand-secondary);
    transition: all 0.4s ease;
  }
}

.backgroundChangeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}

.mobileMenuContainer {
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}

.backgroundChangeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  text-align: center;
  font-size: 20px;
  font-weight: 550;
  gap: 16px;

  .backgroundChangeDescription {
    margin: 0 24px;
    color: var(dark-400);
    text-align: center;
    line-height: 1.2em;
    font-size: 15px;
    font-weight: 400;
  }
}

.backgroundChangeFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  .resetBackgroundButton {
    padding: 0;
    color: var(--danger-200);

    font-weight: 450;
    text-transform: none;
    letter-spacing: 0.5px;

    &:hover,
    &:active {
      opacity: 0.6;
      background: transparent;
      transition: all 0.15s ease;
    }
  }

  .cancelBackgroundButton {
    padding: 0;
    color: var(--light-100);
    opacity: 1;

    font-weight: 450;
    text-transform: none;
    letter-spacing: 0.5px;

    &:hover,
    &:active {
      opacity: 0.6;
      background: transparent;
      transition: all 0.15s ease;
    }
  }
}

.modalWidth {
  max-width: 400px !important;
  width: 100% !important;
}

.modalPosition {
  top: 50vh;
}

@media #{$mobileOnly} {
  .backgroundChangeContainer {
    width: 100%;
  }
}
