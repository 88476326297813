.markerPfp {
  border-radius: 50%;
  border: 2px solid white;
  height: 48px;
  width: 48px;
}

.username,
.ip {
  color: #8b8b8b;
  line-height: 1.2;
}

.historicalMarker {
  background-color: var(--brand-secondary);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid black;
}
