@use "@/styles/variables.scss" as *;
@use "@/styles/functions.scss" as utils;
.superPayContainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.promptContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
}

.paymentInfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: utils.rem(400);
}

.unlockButton {
  padding: utils.rem(10) utils.rem(100) !important;
  border-radius: utils.rem(10) !important;
  margin-top: utils.rem(20) !important;
}

.priceContainer {
  display: flex;
  flex-direction: row;
  color: black;
  padding: utils.rem(10);
  font-family: inherit;
  font-size: utils.rem(20);

  .priceText {
    margin-right: utils.rem(5);
    font-weight: 600;
  }
  .currencyText {
    color: var(--gray-400);
    font-weight: 400;
  }
}

.payToUnlockText {
  color: black;
  padding: utils.rem(15);
  font-family: inherit;
  font-weight: 500;
}

.profilePicture {
  object-fit: cover;
  border-radius: 50%;
  width: utils.rem(70);
  height: utils.rem(70);
}

.termsText {
  color: black;
  width: utils.rem(300);
  text-align: center;
  font-size: utils.rem(10);
  padding: utils.rem(20);
}

.rowContainer {
  display: flex;
  gap: utils.rem(16);
}

.expiryField,
.cvcField {
  flex: 1;
  background-color: "#f5f5f5";
  border-radius: 1;
}

.paymentCard {
  background: #fff;
  padding: 0, utils.rem(20);
  border-radius: 8px;
  border: none;
  box-shadow: none;
}

.unsupportedDeviceWarning {
  margin-top: utils.rem(20);
  font-weight: 500;
}
