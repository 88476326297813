.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.button {
  height: 36px;
}

.shareSection {
  width: 100%;
}

.dividerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #666;
  border: none;
  margin: 0 8px;
}

.dividerText {
  font-size: 14px;
  color: #666;
  white-space: nowrap;
}

.copyLink {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  gap: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.dividerContent {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.copyLinkButton {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  cursor: pointer;
  justify-content: center;
  border: none;
  background: transparent;
  color: #7c899a;
  font-family: inherit;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  height: 40px;
  border-radius: 30px;
  padding: 8px 16px;
  transition:
    color 0.2s ease,
    background 0.2s ease;

  &:hover {
    color: #f8f8f8;
    background: #ffffff14;
  }
}