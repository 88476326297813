@use "@/styles/functions.scss" as utils;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1;
}

.sheet {
  position: fixed;
  left: 50%;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding-inline: utils.rem(24);
  padding-block: utils.rem(40);
  border-radius: utils.rem(16) utils.rem(16) 0 0;
  background: rgba(40, 40, 40);
  max-width: utils.rem(720);
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  overflow: hidden;
  backdrop-filter: blur(utils.rem(50));
}

// .sheetContent {
//   padding: utils.rem(16);
// }

.sheetActions {
  margin-top: utils.rem(16);
  padding: utils.rem(16);
}
