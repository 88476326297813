@use "@/styles/variables.scss" as *;

.wrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 50px;
  width: 100%;
}

.container {
  width: 100%;
  height: 80px;
  max-width: 600px;
  background-color: var(--dark-1300);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  pointer-events: all;
  border-radius: 50px;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
}

.actionButton {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    cursor: pointer;
  }
}

.shareContainer {
  width: 300px;
  border: 1px solid white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  border-radius: 30px;
}

.button {
  height: 36px;
  width: 150px;
  backdrop-filter: none;
}

.share {
  color: var(--brand-secondary) !important;
  border-radius: 32px !important;
}

.unshare {
  background-color: var(--dark-1400) !important;
  color: white !important;
}

.mobileContainer {
  height: 75px;
  width: 100%;
  background-color: var(--dark-1300);
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  pointer-events: all;
}

.actionsContainer {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.analogUsersModal {
  z-index: 3;
}

.analogUsersSheet {
  z-index: 3;
}

@media #{$mobileOnly} {
  .actionButton {
    width: 40px;
    height: 40px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .shareContainer {
    border: none;
    width: 50%;

    .share {
      background-color: var(--brand-secondary) !important;
      color: black !important;
    }
  }
}