@use "@/styles/functions.scss" as utils;

.settingsContainer {
  display: flex;
  flex-direction: column;
  gap: utils.rem(16);

  .subtitle {
    font-size: utils.rem(20);
    line-height: 1.3;
  }

  .unauthorizedName {
    color: var(--dark-400);
  }

  .settingsSection {
    border-radius: utils.rem(8);
    background-color: #262626;
    padding: utils.rem(16);
    display: flex;
    flex-direction: column;
    gap: utils.rem(16);
  }

  .unauthorizedSection {
    button {
      opacity: 0.6;
      cursor: not-allowed !important;
    }
  }

  .unauthorizedMessage {
    background-color: var(--dark-300);
    display: flex;
    align-items: center;
    gap: utils.rem(8);
    padding: 12px;
    border-radius: 4px;
  }

  .iconButton {
    display: flex;
    flex-direction: row;
    gap: utils.rem(8);
    align-items: center;

    // button reset
    border: none;
    padding: 0;
    background: none;
    color: inherit;
    font-size: inherit;
    cursor: pointer;

    &.delete {
      color: var(--danger-200);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    .icon {
      width: 24px;
      height: 24px;
    }
  }
}
