@use "../../../../styles/functions.scss" as utils;

.viewer {
  display: flex;
  flex-direction: row;
  list-style: none;
  gap: utils.rem(16);
  align-items: center;
  justify-content: space-between;

  .userInfo {
    display: flex;
    flex-direction: row;
    gap: utils.rem(8);
    align-items: center;
    text-decoration: none;
    color: white;
    &:hover {
      color: var(--brand-secondary);
    }
  }
  .avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid white;
  }

  .goToButton {
    background-color: rgba(255, 255, 255, 0.1019607843);
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: utils.rem(8);
    cursor: pointer;
    line-height: 1.5;
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: utils.rem(24);
  }
}