.noAccessFullscreen {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  position: absolute;
  gap: 24px;

  z-index: 9999;
  height: 100%;
  width: 100%;

  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
  }

  .requestAccessButton {
    border-radius: 8px !important;
    padding: 8px 16px;

    &:disabled {
      background-color: var(--light-300) !important;
      pointer-events: none;
    }
  }

  .emailInput {
    border-radius: 8px;
  }
}
