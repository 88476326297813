@use "../../styles/functions.scss" as utils;

.dropZone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid var(--dark-300);
  border-radius: utils.rem(16);
  background: var(--dark-200);
  box-shadow: 0px utils.rem(4) utils.rem(4) 0px rgba(0, 0, 0, 0.45) inset;
  transition: all 0.3s ease;
  cursor: pointer;
}

.dropZoneTextGroup {
  display: flex;
  flex-direction: column;
  margin-top: utils.rem(8);
}

.dropZone:hover {
  border: 1px solid var(--brand-primary);
}
