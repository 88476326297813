@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.mobileSortMenuContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .sortByLabel {
    font-family: inherit;
    font-size: utils.rem(20);
    margin-bottom: utils.rem(10);
  }

  .mobileSortFieldButton {
    color: var(--light-200);
    background-color: transparent;
    border: none;
    font-size: utils.rem(16);
    font-family: inherit;
    padding: utils.rem(8);
    width: 100%;
    text-align: left;

    display: flex;
    justify-content: space-between;

    .mobileSortOrderIcon {
      width: utils.rem(19);
    }
  }
}
