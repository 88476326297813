.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dialog {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}