@use "@/styles/functions.scss" as utils;
@use "@/styles/variables.scss" as *;

.sharedUserListItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: utils.rem(40);
  align-items: center;
}

.sharedUserLeft {
  display: flex;
  gap: 16px;
  align-items: center;

  font-size: 13px;
}

.sharedUserAvatar {
  width: 26px;
  aspect-ratio: 1/1;
  border: 2px solid #fff;
  border-radius: 50%;
}

.sharedUserRight {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-right: 2px;
}

.removeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  aspect-ratio: 1/1;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  @media #{$mobileOnly} {
    width: 32px;
  }
}

.removeIcon {
  width: 24px;
  height: auto;
}

.accessTypeSelect {
  all: initial;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: inherit;
  font-size: 13px;
  color: #fff;
  background: url("data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2224%22%20height=%2224%22%20viewBox=%220%200%2024%2024%22%20fill=%22none%22%20stroke=%22white%22%20stroke-width=%222%22%20stroke-linecap=%22round%22%20stroke-linejoin=%22round%22%3E%3Cpath%20d=%22M6%209%2012%2015%2018%209%22/%3E%3C/svg%3E")
    no-repeat;
  background-size: 18px;
  background-position: calc(100% - 3px) 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  padding-right: 24px;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:focus-visible {
    outline: var(--brand-secondary) auto 1px;
    outline-offset: 4px;
  }
}
